<template>
  <v-layout>
        <v-flex xs10>
           <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid }" >
              
            <v-card>
                <v-card-title class="headline">Vue-Form-Generator with Vuetify</v-card-title>
                <v-card-text>
                 
              <form @submit.prevent="handleSubmit(login)">
                    <vue-form-json-schema
                        v-model="model"
                        :schema="schema"
                        :ui-schema="uiSchema"
                      >
                    </vue-form-json-schema>
                    </form>
              
                </v-card-text>
                <v-card-text>
                    <!--<pre><strong>Schema:</strong>{{ schema }}</pre>-->
                    <pre><strong>Model:</strong>{{ model }}</pre>
                    <!--<pre><strong>Counties:</strong>{{ counties }}</pre>-->
                </v-card-text>
                <v-card-actions>
                <v-spacer />
                <v-btn type="submit" color="primary" :disabled="invalid">Login</v-btn>
              </v-card-actions>
              
            </v-card>
            </ValidationObserver>
        </v-flex>
    </v-layout>
</template>

<script>
// MyCustomComponent.js
export default {
  data() {
    return {
      model: {
        
      
      },
      schema: {
        
      },
      uiSchema: [{
          component: 'cmease-text-field',
          model: 'textField',
          fieldOptions: {
            on: ['input'],
            props: {
              counter: 10, 
              maxlength: 5,
              name:"Text Field Type",
              rules:"required|alpha"
            }
          }
        },
        {
          component: 'cmease-checkbox-field',
          model: 'status',
          fieldOptions: {
            on: ['change'],
            props: {
              color: "red",
              name: "Status"
            }
          }
        },
        {
          component: 'cmease-switch-field',
          model: 'statussw',
          fieldOptions: {
            on: ['change'],
            props: {
              color: "red",
              name: "Status Switch"
            }
          }
        },
        {
            component: "cmease-textarea-field",
            model: "testoacaso",
            fieldOptions: {
              on: ['change'],
              props: {
                name: "Testo a caso"
              }
            }

        },
        {
            component: "cmease-select-multi-field",
            
            model: "testselect",
            fieldOptions: {
              on: ['input','change','blur'],
              props: {
                name: "Prova select multipla",
                items:[ 
                        { text: 'Florida', value: 'FL' },
                        { text: 'Georgia', value: 'GA' },
                        { text: 'Nebraska', value: 'NE' },
                        { text: 'California', value: 'CA' },
                        { text: 'New York', value: 'NY' },
                      ]
                      ,rules:"required"
              }
            }
            
        },
        {
            component: "cmease-select-field",
            
            model: "testselect_single",
            fieldOptions: {
              on: ['input','change','blur'],
              props: {
                name: "Prova select singola",
                items:[ 
                        { text: 'Florida', value: 'FL' },
                        { text: 'Georgia', value: 'GA' },
                        { text: 'Nebraska', value: 'NE' },
                        { text: 'California', value: 'CA' },
                        { text: 'New York', value: 'NY' },
                      ]
                      ,rules:"required"
              }
            }
            
        },
        {
            component: "cmease-radio-group-field",
            
            model: "testradiogroup",
            fieldOptions: {
              on: ['input','change','blur'],
              props: {
                name: "Prova radio group",
                items:[ 
                        { text: 'Florida', value: 'FL' },
                        { text: 'Georgia', value: 'GA' },
                        { text: 'Nebraska', value: 'NE' },
                        { text: 'California', value: 'CA' },
                        { text: 'New York', value: 'NY' },
                      ]
                      ,rules:"required"
              }
            }
            
        },
        {
            component: "cmease-complex-example-field",
            
            model: "testcomplex",
            fieldOptions: {
              on: ['input','change','blur'],
              props: {
                name: "Prova select",
                items:[ 
                        { text: 'Florida', value: 'FL' },
                        { text: 'Georgia', value: 'GA' },
                        { text: 'Nebraska', value: 'NE' },
                        { text: 'California', value: 'CA' },
                        { text: 'New York', value: 'NY' },
                      ]
                      ,rules:"required"
              }
            }
            
        }]
    }
  },
}
</script>
